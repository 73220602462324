export const getLeagueFromScore = (score) => {
  const ranks = [
    { rank: 1, coinsToReach: 0 },
    { rank: 2, coinsToReach: 5000 },
    { rank: 3, coinsToReach: 25000 },
    { rank: 4, coinsToReach: 100000 },
    { rank: 5, coinsToReach: 250000 },
    { rank: 6, coinsToReach: 1000000 },
    { rank: 7, coinsToReach: 2500000 },
    { rank: 8, coinsToReach: 5000000 },
    { rank: 9, coinsToReach: 10000000 },
    { rank: 10, coinsToReach: 25000000 },
  ];

  let userLeague = 1; // По умолчанию пользователь находится в 1-й лиге

  for (let i = ranks.length - 1; i >= 0; i--) {
    if (score >= ranks[i].coinsToReach) {
      userLeague = ranks[i].rank;
      break;
    }
  }

  return userLeague;
}

export const formatNumber = (numberString) => {
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const getTitleLeague = (rank) => {
      const LEAGUE =   [
        "Bronze",
        "Silver",
        "Gold",
        "Amettrin",
        "Emerald",
        "Aquamarin",
        "Saphire",
        "Ametist",
        "Ruby",
        "Diamond",
      ];

      return LEAGUE[rank - 1] ?? '';
}
