import React, { useState, useEffect } from "react";
import "./modal.css";

const Modal = ({ isOpen, children, onClose }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    } else {
      setIsVisible(false); // После завершения анимации скрываем модальное окно
      // setIsAnimating(true);
      // setTimeout(() => {
      //   setIsAnimating(false);
      //   onClose();
      // }, 300);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsAnimating(true);
    onClose();
    setTimeout(() => {}, 300);
  };

  const modalStyle = {
    position: "fixed",
    bottom: isAnimating ? "-100%" : "0",
    left: "50%",
    transform: "translate(-50%)",
    width: "100%",
    height: "50vh",
    borderRadius: "24px 24px 0px 0px",
    background: "linear-gradient(180deg, #20053C 0%, #3E0686 95.38%)",
    zIndex: 100,
    transition: "bottom 0.3s ease-in-out",
    display: isVisible ? "block" : "none",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    opacity: isAnimating ? 0 : 1,
    transition: "opacity 0.3s ease-in-out",
    zIndex: 90,
  };

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  return (
    <>
      {isVisible && (
        <div style={overlayStyle} onClick={handleClickOutside}>
          <div style={modalStyle}>
            <div className="modal_body">
              <button onClick={handleClose} className="close-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13.414 12.0002L18.707 6.70719C19.098 6.31719 19.098 5.68325 18.707 5.29325C18.316 4.90225 17.684 4.90225 17.293 5.29325L12 10.5862L6.70701 5.29325C6.31601 4.90225 5.68401 4.90225 5.29301 5.29325C4.90201 5.68325 4.90201 6.31719 5.29301 6.70719L10.586 12.0002L5.29301 17.2933C4.90201 17.6833 4.90201 18.3172 5.29301 18.7072C5.48801 18.9022 5.74401 19.0002 6.00001 19.0002C6.25601 19.0002 6.51201 18.9022 6.70701 18.7072L12 13.4142L17.293 18.7072C17.488 18.9022 17.744 19.0002 18 19.0002C18.256 19.0002 18.512 18.9022 18.707 18.7072C19.098 18.3172 19.098 17.6833 18.707 17.2933L13.414 12.0002Z"
                    fill="white"
                  />
                </svg>
              </button>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
