import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";

import "./header.css";
import {handleRefLink} from "../../../helpers/telegram";

const Header = ({
  score,
  userLeague,
  user,
  handleTouchClick
}) => {
  const totalRef = useRef(null);



  const openInvitePage = (userLeague) => {
    handleTouchClick()
    setTimeout(() => {
      window.location.href = `league`;
    }, 500);
  };

  const vibrate = () => {
    // Проверяем поддержку вибрации
    if ("vibrate" in navigator) {
      navigator.vibrate(100);
    }
  };

  return (
    <div className="header">
      <div className="button-container">
        <div
          className="button hand"
          onClick={() => handleRefLink(user.user?.telegram_id)}
          onTouchStart={vibrate}
        >
          <img
            src="assets/img/hand-ok.png"
            alt=""
            className="button-icon hand-ok"
          />
          <span className="button-text">Invite CASH TEAM</span>
          <span>&nbsp;</span>
          <img src="assets/img/plus-white.png" alt="" className="plus-white" />
        </div>
        <div
          className="button diamond-container"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <img
            // src="assets/img/diamond-icon2.png"
            src={`assets/img/league/diamonds/${userLeague}.png`}
            alt=""
            className="button-icon diamond"
          />
          <span className="button-text">
            {(() => {
              switch (userLeague) {
                case 1:
                  return "Bronze";
                case 2:
                  return "Silver";
                case 3:
                  return "Gold";
                case 4:
                  return "Amettrin";
                case 5:
                  return "Emerald";
                case 6:
                  return "Aquamarin";
                case 7:
                  return "Saphire";
                case 8:
                  return "Ametist";
                case 9:
                  return "Ruby";
                case 10:
                  return "Diamond";
                default:
                  return "";
              }
            })()}
          </span>

          <span>&nbsp;</span>
          <img
            src="assets/img/arrow-right-small.png"
            alt=""
            className="plus-white"
          />
        </div>
      </div>
      <div className="total-div">
        <img src="assets/img/coin.png" alt="" className="img-coin-home" />
        <CSSTransition
          in={true}
          appear={true}
          timeout={2000}
          classNames="fade"
          nodeRef={totalRef}
        >
          <span ref={totalRef} className="total">
            {parseInt(score).toLocaleString()}
          </span>
        </CSSTransition>
      </div>
      <div className="header-row">
        <img src="assets/img/era.png" alt="" className="img-era" />
        <span className="era-text">Genesis Era:</span>
        <svg
          width="13"
          height="17"
          viewBox="0 0 13 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.34048 4.14579C3.34048 2.39938 4.76073 0.979126 6.50715 0.979126C8.25357 0.979126 9.67382 2.39938 9.67382 4.14579C9.67382 5.89221 8.25357 7.31246 6.50715 7.31246C4.76073 7.31246 3.34048 5.89221 3.34048 4.14579ZM8.08333 8.89579H4.91667C1.7025 8.89579 0.5625 11.2495 0.5625 13.265C0.5625 15.0677 1.52125 16.0208 3.33575 16.0208H9.66425C11.4788 16.0208 12.4375 15.0677 12.4375 13.265C12.4375 11.2495 11.2975 8.89579 8.08333 8.89579Z"
            fill="white"
          />
        </svg>
        <div>
          <span className="era-text">{user?.stat?.total_user ?? '...'}</span>
          <span className="era-text-trans">/5500</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
