import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../api/userContext.js";
import Header from "../components/home/header/Header.js";
import Footer from "../components/home/footer/Footer.js";
import { touchClick } from "../api/apiRequests.js";
import {getLeagueFromScore} from "../utils/utils.js";
import CubeLight from "../components/home/clicker/CubeLight.js";
import "./styles.css";

const App = () => {
  const user = useUser(); // Получаем данные пользователя из контекста
  const [isClicking, setIsClicking] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(null);
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);
  const clicksRef = useRef(0);
  const clickerRef = useRef(null);
  const [opacity, setOpacity] = useState(0);

  const [score, setScore] = useState(0);
  const [energy, setEnergy] = useState(1000);
  const [userLeague, setUserLeague] = useState(1);

  const handleTouchClick = () => {
    touchClick(clicksRef.current).then(
        (data) => {
          if (data){
            setEnergy(data.available_coins);
            setScore(data.balance_coins);
            setUserLeague(data.league_id);
          }
        }
    );
  }

  useEffect(() => {
    if (user) {
      setScore(user.balance_coins);
      setEnergy(user.available_coins);
      setUserLeague(user.league_id);

    }
  }, [user]);

  useEffect(() => {
    if (isClicking) {
      const clickTimer = setTimeout(() => {
        setIsClicking(false);
        if (Date.now() - lastClickTime >= 1500) {
          handleTouchClick();
          clicksRef.current = 0;
        }
      }, 1500 - (Date.now() - lastClickTime));
      return () => clearTimeout(clickTimer);
    }
  }, [
    isClicking,
    clicksRef,
    lastClickTime,
  ]);

  // Проба запретить прокрутку на самом кубе
  useEffect(() => {
    const clickerElement = clickerRef.current;
    if (!clickerElement) return;

    const handleTouchMove = (event) => {
      event.preventDefault();
    };

    clickerElement.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });

    return () => {
      clickerElement.removeEventListener("touchmove", handleTouchMove);
    };
  }, [clickerRef]);

  const handleTouchStart = (event) => {
    if (energy > 0) {
      logoAnimation();
      setIsClicking(true);
      setLastClickTime(Date.now());
      const addScore = Math.min(user.multiple_clicks, energy)
      setScore((prevScore) => +prevScore + addScore);
      setEnergy((prevEnergy) => +prevEnergy - addScore);
      clicksRef.current += addScore;

      if (clicksRef.current === 200) {
        handleTouchClick()
        clicksRef.current = 0;
      }

      for (let i = 0; i < event.touches.length; i++) {
        const clickerElement = document.getElementById("clicker");
        const { left, top } = clickerElement.getBoundingClientRect();
        createFlyingCoin(left, top);
        handleOpacityIncrease();
      }
    }
  };

  const logoAnimation = () => {
    if (!isAnimationRunning) {
      setIsAnimationRunning(true);

      const logoTopContainer = document.querySelector(".logo-top-container");
      const logoBottom = document.querySelector(".logo-bottom");
      const logoCenter = document.querySelector(".logo-center");

      logoTopContainer.classList.toggle('active');
      logoBottom.classList.toggle('active');
      logoCenter.style.transform = "scale(1.03)";

      setTimeout(() => {
        logoTopContainer.classList.toggle('active');
        logoBottom.classList.toggle('active');
        logoCenter.style.transform = "scale(1)";

        setIsAnimationRunning(false);
      }, 100);
    }
  };

  const createFlyingCoin = (x, y) => {
    // Создаем элемент монеты
    const coinElement = document.createElement("div");
    coinElement.classList.add("touch-feedback2", "fly-up");

    let img = document.createElement("img");
    img.src = "assets/img/coin.png";
    img.style.width = "20px";
    img.style.height = "20px";
    img.classList.add("coin-image");

    coinElement.appendChild(img);

    const randomOffset = Math.floor(Math.random() * (190 - 70 + 1)) + 70;
    coinElement.style.left = x + randomOffset + "px";

    coinElement.style.top = y + "px";
    document.body.appendChild(coinElement);

    // Удаляем монетку после завершения анимации
    coinElement.addEventListener("animationend", () => {
      document.body.removeChild(coinElement);
    });
  };

  // Обработка свечения над кубом
  const handleOpacityIncrease = () => {
    setOpacity((prevOpacity) => Math.min(prevOpacity + 0.03, 1));
  };

  // Уменьшаем opacity до начального значения по интервалу
  useEffect(() => {
    const timer = setInterval(() => {
      setOpacity((prevOpacity) => {
        const newOpacity = Math.max(prevOpacity - 0.01, 0);
        if (newOpacity === 0) {
          setOpacity(0);
          clearInterval(timer);
        }
        return newOpacity;
      });
    }, 80);

    return () => clearInterval(timer);
  }, [opacity]);

  const vibrate = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(100);
    }
  };

  if (!user.balance_coins) {
    return (
      <div className="test">
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="wrapper stopVertScroll">
        <Header
          score={score}
          userLeague={userLeague}
          user={user}
          handleTouchClick={handleTouchClick}

        />

        <div className="clicker-container">
          <div id="clicker" onTouchStart={handleTouchStart}>
            <CubeLight
              fill="linear-gradient(180deg, rgba(4, 9, 24, 0.00) -11.49%, rgba(28, 10, 82, 0.16) 52.29%, rgba(255, 88, 35, 0.39) 80.75%, rgba(255, 141, 35, 0.78) 94.38%)"
              filter="blur(12.5px)"
              opacity={opacity}
            />
            <div className="light-logo"></div>

            <img src="assets/img/cubeNew3.png" alt="" className="clicker-img" />
            <div className="logo-top-container">
              <img
                src="assets/img/logo/logo-top.png"
                alt=""
                className=" logo logo-top"
              />
            </div>
            <img
              src="assets/img/logo/logo-center.png"
              alt=""
              className="logo logo-center"
            />
            <img
              src="assets/img/logo/logo-bottom.png"
              alt=""
              className="logo logo-bottom"
            />
            <div className="back-circle"></div>
          </div>
        </div>

        <Footer
          energy={energy}
          setEnergy={setEnergy}
          vibrate={vibrate}
          user={user}
          handleTouchClick={handleTouchClick}
        />
      </div>
    </div>
  );
};

export default App;
