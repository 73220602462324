// Home.js
import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {UserProvider} from './api/userContext';
import HomePage from './pages/HomePage'; // Імпортуйте компонент HomePage
import LeaguePage from './pages/league/LeaguePage'; // Імпортуйте компонент LeaguePage
import Boosts from "./pages/boosts/boosts";
import EarnPage from "./pages/earn/earn";
import TeamPage from "./pages/team/team";
import TotalPlayersPage from "./pages/totalPlayers/totalPlayers";
import King from "./pages/king/King";

const Home = () => {

    window.Telegram.WebApp.expand()
    const BackButton = window.Telegram.WebApp.BackButton
    // window.Telegram.WebApp.setHeaderColor('#000000');
    window.Telegram.WebApp.enableClosingConfirmation();

    BackButton.onClick(function () {

        if (window.location.pathname === '/team/king') {
            window.location.href = "/team";
            return;
        }

        if (window.location.pathname === '/total-players') {
            window.location.href = "/league";
            return;
        }

        window.location.href = "/";
        BackButton.hide();
    });


    useEffect(() => {
        if (window.location.pathname === '/') {
            return;
        }
        BackButton.show()

    }, [BackButton])


    return (
        <Router>
            <UserProvider>
                <Switch>
                    {/* <Route exact path="/" render={() => <HomePage  score={ score } energy = { energy } setScore = { setScore } setEnergy = { setEnergy }/>} /> */}
                    <Route exact path="/" render={() => <HomePage/>}/>
                    <Route exact path="/league" component={LeaguePage}/>
                    <Route exact path="/earn" component={EarnPage}/>
                    <Route exact path="/boosts" component={Boosts}/>
                    <Route exact path="/team" component={TeamPage}/>
                    <Route exact path="/team/king" component={King}/>
                    <Route exact path="/total-players" component={TotalPlayersPage}/>
                </Switch>
            </UserProvider>
        </Router>
    );
};

export default Home;
