import React from "react";
import "./totalPlayers.css";
import { formatNumber } from "../../utils/utils";
import {handleRefLink} from "../../helpers/telegram";
import {useUser} from "../../api/userContext";


const TotalPlayersPage = () => {
  const user = useUser();

  if (!user?.stat?.total_coins) {
    return (
        <div className="test">
          <h1>Loading...</h1>
        </div>
    );
  }

  return (
    <div className="wrapper pgT-60">
      <div className="your-balance mrB-12">
        <p className="mrB-12">Total CashCoin balance</p>

        <div className="total-div-totalPlayers">
          <img
            src="assets/img/coin.png"
            alt=""
            className="img-coin-totalPlayers"
          />
          <span className="balance_coins-totalPlayers">{user?.stat?.total_coins && formatNumber(user.stat.total_coins)}</span>
        </div>
      </div>

      <div className="small-button-white-text-totalPlayer">
        <span>What does it mean</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M12.5 22.8511C6.572 22.8511 1.75 18.0291 1.75 12.1011C1.75 6.17307 6.572 1.35107 12.5 1.35107C18.428 1.35107 23.25 6.17307 23.25 12.1011C23.25 18.0291 18.428 22.8511 12.5 22.8511ZM12.5 2.85107C7.399 2.85107 3.25 7.00007 3.25 12.1011C3.25 17.2021 7.399 21.3511 12.5 21.3511C17.601 21.3511 21.75 17.2021 21.75 12.1011C21.75 7.00007 17.601 2.85107 12.5 2.85107ZM13.2109 13.4651C13.2349 13.3931 13.371 13.1201 14.103 12.6301C15.283 11.8391 15.811 10.709 15.592 9.44702C15.37 8.17202 14.3309 7.1251 13.0649 6.9021C12.1399 6.7381 11.203 6.98701 10.491 7.58301C9.77197 8.18601 9.36011 9.07096 9.36011 10.012C9.36011 10.426 9.69611 10.762 10.1101 10.762C10.5241 10.762 10.8601 10.426 10.8601 10.012C10.8601 9.51596 11.0771 9.04918 11.4551 8.73218C11.8311 8.41718 12.311 8.28817 12.804 8.37817C13.45 8.49217 14.001 9.04913 14.115 9.70312C14.158 9.95012 14.2891 10.7001 13.2681 11.3831C12.4411 11.9381 11.971 12.4471 11.79 12.9871C11.658 13.3801 11.8699 13.805 12.2629 13.937C12.3419 13.964 12.422 13.9761 12.501 13.9761C12.813 13.9761 13.1049 13.7781 13.2109 13.4651ZM13.52 16.6011C13.52 16.0491 13.073 15.6011 12.52 15.6011H12.51C11.958 15.6011 11.5149 16.0491 11.5149 16.6011C11.5149 17.1531 11.968 17.6011 12.52 17.6011C13.072 17.6011 13.52 17.1531 13.52 16.6011Z"
            fill="white"
          />
        </svg>
      </div>

      <div className="main-table-tp ">
        <div className="total-info-league-tp">
          <div className="main-info-list-league-tp">
            <div className="item__user-template-league">
              <img
                src="assets/img/totalPlayers/Ellipses.png"
                alt=""
                className="item__user-avatar-league-tp"
              />
            </div>

            <div className="info-text-tp">
              <div className="info-type-tp">Total player</div>
              <span className="league-info-num">{user?.stat?.total_user && formatNumber(user.stat.total_user)}</span>
            </div>
          </div>
        </div>

        <div className="total-info-league-tp">
          <div className="main-info-list-league-tp">
            <div className="item__user-template-league">
              <img
                src="assets/img/totalPlayers/Ellipses.png"
                alt=""
                className="item__user-avatar-league-tp"
              />
            </div>

            <div className="info-text-tp">
              <div className="info-type-tp">Daily users</div>
              <span className="league-info-num">{user?.stat?.daily_user && formatNumber(user.stat.daily_user)}</span>
            </div>
          </div>
        </div>
        <div className="total-info-league-tp">
          <div className="main-info-list-league-tp">
            <div className="item__user-template-league">
              <img
                src="assets/img/totalPlayers/Ellipses.png"
                alt=""
                className="item__user-avatar-league-tp"
              />
            </div>

            <div className="info-text-tp">
              <div className="row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                  className="online-pin"
                >
                  <path
                    d="M5.00004 9.58016C2.53004 9.58016 0.520874 7.57099 0.520874 5.10099C0.520874 2.63099 2.53004 0.621826 5.00004 0.621826C7.47004 0.621826 9.47921 2.63099 9.47921 5.10099C9.47921 7.57099 7.47004 9.58016 5.00004 9.58016ZM5.00004 1.24683C2.87462 1.24683 1.14587 2.97558 1.14587 5.10099C1.14587 7.22641 2.87462 8.95516 5.00004 8.95516C7.12546 8.95516 8.85421 7.22641 8.85421 5.10099C8.85421 2.97558 7.12546 1.24683 5.00004 1.24683ZM7.50004 5.10099C7.50004 3.72016 6.38087 2.60099 5.00004 2.60099C3.61921 2.60099 2.50004 3.72016 2.50004 5.10099C2.50004 6.48183 3.61921 7.60099 5.00004 7.60099C6.38087 7.60099 7.50004 6.48183 7.50004 5.10099Z"
                    fill="#00FF0A"
                  />
                </svg>
                <div className="info-type-tp">Online</div>
              </div>
              <span className="league-info-num">{user?.stat?.online && formatNumber(user.stat.online)}</span>
            </div>
          </div>
        </div>
      <div className="button-big-yellow-tp" onClick={() => handleRefLink(user.user?.telegram_id)}>Invite a friend</div>
      </div>

    </div>
  );
};

export default TotalPlayersPage;
